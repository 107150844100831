var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "区域", visible: _vm.dialogVisible, width: "400px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.data,
              "node-key": _vm.key1,
              props: {
                label: "areaName",
                children: "childrenAreas",
                isLeaf: "leaf",
              },
              load: _vm.loadNode1,
              "check-strictly": true,
              lazy: "",
              "show-checkbox": "",
            },
            on: { "node-click": _vm.handleNodeClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.sureButton } },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }