<template>
  <div class="page1">
    <div @keydown.enter.prevent="submitData">
      <el-form
        label-position="right"
        label-width="150px"
        :model="formInline"
        :rules="rules"
        :ref="'form'"
      >
        <div class="content bgFFF">
          <h2 class="title">
            {{ isEdit ? "编辑" : "添加" }}封闭车场
          </h2>
          <div class="flex-box">
            <el-form-item label="车场层级" prop="slaveRelation">
              <el-select
                v-model.trim="formInline.slaveRelation"
                :disabled="this.isEdit"
                filterable
                placeholder="请选择"
                @change="slaveRelationChange"
              >
                <el-option
                  v-for="item in slaveRelation"
                  :key="item.code"
                  :disabled="item.disabled"
                  :label="item.desc"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否接入远程值守" prop="remoteControl">
                        <el-radio v-model="formInline.remoteControl" :label="0">否</el-radio>
                        <el-radio v-model="formInline.remoteControl" :label="1">是</el-radio>
                      </el-form-item> -->
            <el-form-item
              label="关联车场"
              :prop="formInline.slaveRelation > 1 ? 'parentId' : ''"
              v-show="formInline.slaveRelation > 1"
            >
              <el-select
                :disabled="this.isEdit"
                v-model.trim="formInline.parentId"
                filterable
                placeholder="请选择"
                @change="slaveRelationChange1"
              >
                <el-option
                  :label="value.parkName"
                  :value="value.parkId"
                  :key="value.parkId"
                  v-for="value in parkParent"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.park_name')" prop="parkName">
              <el-input
                :maxlength="20"
                v-model.trim="formInline.parkName"
                placeholder="请输入车场名称"
              >
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.parking_code')" prop="parkAlias">
              <el-input
                :maxlength="20"
                v-model.trim="formInline.parkAlias"
                placeholder="请输入车场编码"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="车场物理类型" prop="closedParkType">
              <el-select v-model.trim="formInline.closedParkType" filterable placeholder="请选择">
                <el-option
                  :label="value.desc"
                  :value="value.code"
                  :key="value.code"
                  v-for="value in parkType"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="flex">
              <el-form-item label="接入方式及值守模式" prop="parkAccessType">
                <el-select
                  v-model="formInline.parkAccessType"
                  placeholder="请选择接入方式"
                  :disabled="second"
                  @change="parkAccessChange(1)"
                  class="custom-select"
                >
                  <el-option :label="v.desc" :value="v.code" :key="v.code" v-for="v in accessList">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="parkSystemType" class="duty-mode">
                <el-select
                  v-model.trim="formInline.parkSystemType"
                  filterable
                  placeholder="请选择值守模式"
                  @change="parkSystemTypeChange"
                  class="custom-select"
                >
                  <el-option
                    v-for="item in formInline.parkAccessType !== 3
                      ? parkSystemType
                      : parkSystemType.filter((v, index) => index)"
                    :key="item.code"
                    :disabled="item.disabled"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item
              :label="`${level}泊位数`"
              :prop="'amount'"
              :rules="[
                {
                  required: formInline.parkAccessType != 3,
                  message: '请输入泊位数',
                },
              ]"
            >
              <el-input
                :maxlength="10"
                v-model.number="formInline.amount"
                @input="($event) => timeNumber($event, 'amount')"
                :disabled="formInline.parkAccessType === 3 && formInline.slaveRelation !== 1"
                :placeholder="`请输入${level}泊位数`"
              ></el-input>
            </el-form-item>
            <template v-if="formInline.parkAccessType === 3">
              <el-form-item label="固定泊位数" prop="fixedAmount">
                <el-input
                  :maxlength="10"
                  v-model="formInline.fixedAmount"
                  @input="($event) => timeNumber($event, 'fixedAmount')"
                  placeholder="请输入固定泊位数"
                  @change="parkAccessChange"
                ></el-input>
              </el-form-item>
              <el-form-item label="临停泊位数" prop="tempAmount">
                <el-input
                  :maxlength="10"
                  v-model.number="formInline.tempAmount"
                  @input="($event) => timeNumber($event, 'tempAmount')"
                  placeholder="请输入临停泊位数"
                  @change="parkAccessChange"
                ></el-input>
              </el-form-item>
              <el-form-item label="固定泊位车辆" prop="fixedBerthCarType">
                <el-select
                  v-model="formInline.fixedBerthCarType"
                  collapse-tags
                  class="gdbw-select"
                  multiple
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in carGroup"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                    :disabled="item.code == 4"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
            <el-form-item
              label="一级车场泊位数"
              :prop="'firstLevelAmount'"
              v-if="formInline.slaveRelation == 1"
              :rules="[
                {
                  required: formInline.parkAccessType != 3,
                  message: '请输入一级车场泊位数',
                },
              ]"
            >
              <el-input
                :maxlength="10"
                v-model.number="formInline.firstLevelAmount"
                placeholder="请输入一级车场泊位数"
                @input="($event) => timeNumber($event, 'firstLevelAmount')"
                :disabled="formInline.parkAccessType === 3"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.region')" prop="areaId">
              <el-button
                :disabled="second"
                :title="areaName ? areaName : '请选择'"
                @click="areaIdDialog"
                class="dialog_button"
                >{{ areaName ? areaName : "请选择" }}</el-button
              >
            </el-form-item>
            <el-form-item label="经纬度" class="lng-box" prop="longitude">
              <el-input
                :maxlength="20"
                v-model.trim="lnglat"
                placeholder="在地图上选择坐标点"
                :disabled="second"
              ></el-input>
              <el-button :disabled="second" type="primary" class="map1" @click="openMap"
                >地图选点</el-button
              >
            </el-form-item>
            <el-form-item :label="$t('searchModule.address')" prop="parkAddress">
              <el-input
                type="textarea"
                :maxlength="20"
                :autosize="{ minRows: 2, maxRows: 1 }"
                v-model.trim="formInline.parkAddress"
                :disabled="second"
                placeholder="输入车场详细地址"
              ></el-input>
            </el-form-item>
            <div class="uploadPic">
              <div class="park-title">车场图片</div>
              <el-upload
                ref="upload"
                class="avatar-uploader2"
                action="/acb/2.0/bacb/park/upload"
                :headers="uploadHeader"
                :data="uploadData"
                name="image"
                :on-change="imgChange"
                :file-list="fileList"
                :auto-upload="false"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar2" />
                <i v-else class="el-icon-plus avatar-uploader-icon2"></i>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title-box" @click="isOperate = !isOperate">
            <i :class="isOperate ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            <span>车场运营信息</span>
          </div>
          <div v-show="isOperate" class="flex-box mt20">
            <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
              <el-select
                :disabled="isEdit || second"
                v-model.trim="formInline.operationId"
                filterable
                size="15"
              >
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否商业车场" prop="businessType">
              <el-select
                v-model="formInline.businessType"
                placeholder="请选择是否商业车场"
                :disabled="second"
              >
                <el-option :label="v.desc" :value="v.code" :key="v.code" v-for="v in businessType">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否承包" prop="contract">
              <el-select v-model="formInline.contract" placeholder="请选择是否承包">
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合作模式" prop="cooperationModel">
              <el-select
                v-model="formInline.cooperationModel"
                placeholder="请选择合作模式"
                :disabled="second"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in cooperationModel"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车场开放时间" :rules="rules.date" class="time-width">
              <el-time-picker
                is-range
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm:ss"
                v-model="date"
              >
              </el-time-picker>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Commercial_status')" prop="commercialStatus">
              <el-select
                v-model="formInline.commercialStatus"
                placeholder="请选择"
                @change="onChangeCommercialStatus"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in commercialStatus"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车场状态" prop="parkState">
              <el-select v-model="formInline.parkState" placeholder="请选择">
                <el-option :label="v.desc" :value="v.code" :key="v.code" v-for="v in parkState">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否支持充电" prop="chargeable">
              <el-select v-model="formInline.chargeable" placeholder="请选择是否支持充电">
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="0" label="是否支持欠费离场" prop="debtLeave">
              <el-select v-model="formInline.debtLeave" placeholder="请选择">
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="计费规则" prop="thirdFeeDesc">
              <el-input
                type="textarea"
                size="medium"
                :autosize="{ minRows: 4, maxRows: 8 }"
                :maxlength="400"
                v-model.trim="formInline.thirdFeeDesc"
                placeholder="输入计费规则"
              ></el-input>
            </el-form-item>
            <el-form-item class="hotArea" label="所属热门商圈">
              <el-transfer
                target-order="push"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入商圈名称"
                v-model="formInline.hotValue"
                @change="change"
                :props="{
                  key: 'businessCenterId',
                  label: 'businessCenterName',
                }"
                :titles="['未选择', '已选择']"
                :data="hotData"
              >
              </el-transfer>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="form-footer">
      <!-- submitData -->
      <el-button type="primary" @click="addSub">提交</el-button>
      <el-button type @click="$router.go(-1)">取消</el-button>
    </div>
    <map-con ref="mapConpent" :city="city" @mapCancel="mapCancel" @mapSure="mapSure"></map-con>
    <tree-con
      ref="treeConpent"
      :defaultProps="defaultProps"
      :treeData="treeData"
      @treeSure="treeSure"
      @treeCandle="treeCandle"
    ></tree-con>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
import mapCon from "@/components/map";
import treeCon from "./tree";

export default {
  name: "outAddClosePark",
  data() {
    // 临停、固停校验
    let berthNum = (rule, value, callback) => {
      if (value != parseInt(value)) {
        return callback(new Error("泊位数量必须为整数"));
      }
      if (value < 0) {
        callback(new Error("泊位数量不能小于0"));
      } else {
        callback();
      }
    };
    let checkDate = (rule, value, callback) => {
      if (value[0] == "") {
        return callback(new Error("请选择开放时间"));
      } else if (value[1] == "") {
        return callback(new Error("请选择结束时间"));
      } else {
        callback();
      }
    };
    return {
      // 0：AI值守；1：有人值守；2：无人值守；3：远程值守
      parkSystemType: [
        // {
        //   desc: "AI值守",
        //   code: 0,
        //   disabled: false
        // },
        {
          desc: "有人值守",
          code: 1,
          disabled: false,
        },
        {
          desc: "无人值守",
          code: 2,
          disabled: false,
        },
        {
          desc: "远程值守",
          code: 3,
          disabled: false,
        },
      ],
      carGroup: [], // 固定泊位车辆下拉选项
      timer: 0,
      second: false,
      level: "独立车场",
      fileList: [],
      uploadImg: null,
      fileChang: false,
      parkState: [],
      parkScope: [],
      key1: "",
      city: "",
      parkName: "",
      dis: false,
      lnglat: "",
      date: ["08:00:00", "23:59:59"],
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        parkId: "",
      },
      workStateAll: [],
      treeData: [],
      parkType: [],
      parkList: "",
      parkParent: [],
      butreeData: [],
      areaName: "",
      businessName: "",
      businessType: [],
      slaveRelation: [],
      cooperationModel: [],
      commercialStatus: [],
      parkEquipmentType: [],
      imageUrl: "",
      payMode: [],
      isEdit: false,
      loading: false,
      effective: "1",
      startTime: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      tenantList: [],
      accessList: [
        {
          code: 1,
          desc: "MB云盒",
        },
        {
          code: 2,
          desc: "平台对平台",
        },
        {
          code: 3,
          desc: "轻量化云盒",
        },
      ],
      // 接入方式列表
      hotData: [],
      parkImgList: [],
      limitCount: 2,
      isOperate: true,
      hideUpload: false,
      tempCommercialStatus: 0,
      formInline: {
        parkSystemType: "",
        thirdFeeDesc: "",
        remoteControl: "",
        parentId: "",
        slaveRelation: 0,
        parkName: "",
        parkAlias: "",
        amount: "",
        firstLevelAmount: "",
        cityName: "",
        hotValue: [],
        areaName: "",
        areaId: "",
        operationId: "",
        longitude: "",
        openStartTime: "",
        openStopTime: "",
        latitude: "",
        parkAddress: "",
        businessType: "",
        contract: "",
        cooperationModel: "",
        commercialStatus: "",
        parkState: "",
        chargeable: "",
        debtLeave: 0, // 是否支持欠费离场
        parkAccessType: "",
        fixedAmount: "",
        tempAmount: "",
        fixedBerthCarType: [4],
      },
      rules: {
        remoteControl: [
          {
            required: true,
            message: "请选择是否接入远程值守",
            trigger: "blur",
          },
        ],
        parkSystemType: [
          {
            required: true,
            message: "请选择值守模式",
            trigger: "blur",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择车场开放结束时间",
          },
          {
            validator: checkDate,
            trigger: "blur",
          },
        ],
        fixedAmount: [
          {
            required: true,
            message: "请输入固定泊位数",
            trigger: "blur",
          },
          {
            validator: berthNum,
            trigger: "blur",
          },
        ],
        tempAmount: [
          {
            required: true,
            message: "请输入临停泊位数",
            trigger: "blur",
          },
          {
            validator: berthNum,
            trigger: "blur",
          },
        ],
        commercialStatus: [
          {
            required: true,
            message: "请选择商用状态",
            trigger: "blur",
          },
        ],
        parkAlias: [
          {
            required: true,
            message: "请输入车场编码",
            trigger: "blur",
          },
        ],
        businessType: [
          {
            required: true,
            message: "请选择是否商业车场",
            trigger: "blur",
          },
        ],
        innerPayable: [
          {
            required: true,
            message: "请选择场内支付",
            trigger: "blur",
          },
        ],
        payMode: [
          {
            required: true,
            message: "请选择支付方式",
            trigger: "blur",
          },
        ],
        cooperationModel: [
          {
            required: true,
            message: "请选择合作模式",
            trigger: "blur",
          },
        ],
        // scopeId: [
        //   {
        //     required: true,
        //     message: "请选择计费类型",
        //     trigger: "blur"
        //   }
        // ],
        parkName: [
          {
            required: true,
            message: "请输入车场名称",
            trigger: "blur",
          },
          // , {validator: checkName, trigger: 'blur'}
        ],
        closedParkType: [
          {
            required: true,
            message: "请选择车场类型",
            trigger: "blur",
          },
        ],
        parentId: [
          {
            required: true,
            message: "请选择车场名称",
            trigger: "blur",
          },
        ],
        parkState: [
          {
            required: true,
            message: "请选择车场状态",
            trigger: "blur",
          },
        ],
        slaveRelation: [
          {
            required: true,
            message: "请选择车场类型",
            trigger: "blur",
          },
        ],
        areaId: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur",
          },
        ],
        longitude: [
          {
            required: true,
            message: "请在地图选点",
            trigger: ["blur", "change"],
          },
        ],
        parkAddress: [
          {
            required: true,
            message: "输入车场详细地址",
            trigger: ["blur", "change"],
          },
        ],
        contract: [
          {
            required: true,
            message: "请选择是否承包",
            trigger: "blur",
          },
        ],
        chargeable: [
          {
            required: true,
            message: "请选择是否支持充电",
            trigger: "blur",
          },
        ],
        debtLeave: [
          {
            required: true,
            message: "请选择是否支持欠费离场",
            trigger: "blur",
          },
        ],
        parkAccessType: [
          {
            required: true,
            message: "请选择接入方式",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    timeNumber(event, value) {
      event = event.replace(/[^\d]/g, "");
      if (["amount", "firstLevelAmount"].includes(value)) {
        event = event.replace(/^0/g, "");
      }
      this.formInline[value] = event;
    },
    change(date) {
      console.log(date);
      this.$set(this.formInline, "hotValue", date);
      console.log(this.formInline.hotValue);
    },
    parkSystemTypeChange(date) {
      console.log(date);
      // console.log(this.formInline.slaveRelation);
      this.formInline.slaveRelation =
        this.formInline.slaveRelation && this.formInline.slaveRelation == 0
          ? ""
          : this.formInline.slaveRelation;
      if (date == 0) {
        this.slaveRelation.forEach((v, index) => {
          if (index != 0) {
            this.$set(
              this.slaveRelation,
              index,
              Object.assign(v, {
                disabled: true,
              })
            );
          }
        });
      } else {
        this.slaveRelation.forEach((v, index) => {
          this.$set(
            this.slaveRelation,
            index,
            Object.assign(v, {
              disabled: false,
            })
          );
        });
      }
    },
    // 接入方式改变时触发
    parkAccessChange(type) {
      const { fixedAmount = "", tempAmount = "", parkAccessType, slaveRelation } = this.formInline;
      if (parkAccessType === 3) {
        // 清除总泊位数校验
        this.$refs["form"].clearValidate(["amount", "firstLevelAmount"]);
        if (slaveRelation === 1) {
          this.formInline.firstLevelAmount = Number(fixedAmount) + Number(tempAmount);
          this.formInline.amount = "";
        } else {
          this.formInline.amount = Number(fixedAmount) + Number(tempAmount);
        }
      } else {
        this.formInline.firstLevelAmount = "";
        this.formInline.amount = "";
        this.formInline.fixedAmount = "";
        this.formInline.tempAmount = "";
      }
      // 切换接入方式，清空值守模式
      if (type === 1) {
        this.formInline.parkSystemType = "";
      }
    },
    // 车场层级切换，清空选项
    slaveRelationClear() {
      this.formInline.firstLevelAmount = "";
      this.formInline.amount = "";
      this.formInline.fixedAmount = "";
      this.formInline.tempAmount = "";
      this.formInline.fixedBerthCarType = [4];
      this.second = !(this.formInline.slaveRelation < 2);
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    openTimeChange(value) {
      this.formInline.openStartTime = value[0];
      this.formInline.openStopTime = value[1];
    },
    slaveRelationChange(slaveRelation) {
      // console.log(slaveRelation)
      switch (slaveRelation) {
        case 0:
          this.level = "独立车场";
          break;
        case 1:
          this.level = "总";
          break;
        case 2:
          this.level = "二级车场";
          break;
        case 3:
          this.level = "三级车场";
          break;
        case 4:
          this.level = "四级车场";
          break;
        default:
          this.level = "独立车场";
      }
      // this.formInline.parkSystemType = this.formInline.parkSystemType && this.formInline
      //   .parkSystemType == 3 && this.formInline.slaveRelation != 0 ? '' : this.formInline.parkSystemType
      // if (slaveRelation == 0) {
      //   this.$set(this.parkSystemType, 0, {
      //     desc: "AI值守",
      //     code: 0,
      //     disabled: false
      //   })
      // } else {
      //   this.$set(this.parkSystemType, 0, {
      //     desc: "AI值守",
      //     code: 0,
      //     disabled: true
      //   })
      // }
      if (slaveRelation > 1) {
        this.hotData = this.hotData.map((res) => {
          res.disabled = true;
          return res;
        });
      } else {
        this.hotData = this.hotData.map((res) => {
          res.disabled = false;
          return res;
        });
      }
      this.getParentPark(slaveRelation);
      !this.isEdit && this.slaveRelationClear();
    },
    slaveRelationChange1(slaveRelation) {
      this.formInline.parentId = slaveRelation;
      let url = "/acb/2.0/bacb/park/getParkVOById/" + slaveRelation;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.second = true;
          let data = res.value;
          this.areaName = data.areaName;
          this.formInline.areaId = data.areaId;
          this.formInline.operationId = data.operationId;
          this.businessName = data.operationName;
          this.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.formInline.longitude = data.longitude;
          this.formInline.latitude = data.latitude;
          this.formInline.parkAddress = data.parkAddress;
          this.formInline.scopeId = data.scopeId;
          this.formInline.businessType = data.businessType;
          this.formInline.cooperationModel = data.cooperationModel;
          this.formInline.parkAccessType = data.parkAccessType;
          // this.formInline.innerPayable = data.innerPayable;
          // this.formInline.payMode = data.payMode;
          // this.formInline.parkEquipmentType = data.parkEquipmentType;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
      this.getHotAreaDetails(slaveRelation);
    },
    getParentDetail(parkId) {
      let url = "/acb/2.0/bacb/park/getParkVOById/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.areaName = data.areaName;
          this.formInline.areaId = data.areaId;
          this.formInline.operationId = data.operationId;
          this.businessName = data.operationName;
          this.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.formInline.longitude = data.longitude;
          this.formInline.latitude = data.latitude;
          this.formInline.parkAddress = data.parkAddress;
          this.formInline.scopeId = data.scopeId;
          this.formInline.businessType = data.businessType;
          this.formInline.cooperationModel = data.cooperationModel;
          this.formInline.commercialStatus = data.commercialStatus;
          this.tempCommercialStatus = data.commercialStatus;
          this.formInline.innerPayable = data.innerPayable;
          this.formInline.payMode = data.payMode;
          // this.formInline.parkEquipmentType = data.parkEquipmentType;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    imgChange(file, list) {
      this.fileList = list.slice(-1);
      this.uploadImg = file;
      this.fileChang = true;
      this.imageUrl = file.url;
      if (!this.imageUrl) this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess(res) {
      if (res.state == 0) {
        this.$router.push("/parkManage");
      } else {
        this.$alert(res.desc, this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    areaIdDialog() {
      this.defaultProps = {
        label: "areaName",
        children: "childs",
      };
      this.key1 = "areaId";
      this.$refs.treeConpent.open();
    },
    getArea() {
      let url = "/acb/2.0/managerArea/areaAll";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParentPark() {
      let url = "/acb/2.0/bacb/park/getParentParks";
      this.$axios
        .get(url, {
          data: {
            slaveRelation: this.formInline.slaveRelation - 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkParent = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    sortArea(res) {
      let arr = [];
      res.forEach((value) => {
        value.childs = [];
        if (value.parentId == 0) {
          arr.push(value);
        }
      });

      function getChild(data) {
        data.forEach((value) => {
          value.childs = res.filter((child) => {
            if (child?.depth == 2) {
              child.disabled = true;
            }
            return child.parentId == value.areaId;
          });
          getChild(value.childs);
        });
      }

      getChild(arr);
      return arr;
    },
    openMap() {
      // let city = this.treeData[0].areaName;
      this.$refs.mapConpent.openMap(this.lnglat);
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    treeSure(data) {
      if (data.length > 1) {
        this.$alert("只能选择一个节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      } else if (data.length == 0) {
        this.$alert("您未选择节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.key1 == "areaId") {
        this.formInline[this.key1] = data[0]["areaId"];
        this.formInline.areaName = data[0]["areaName"];
        this.areaName = data[0]["areaName"];
      }
      this.$refs.treeConpent.close();
    },
    treeCandle() {},
    mapSure(opt) {
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      this.formInline.parkAddress = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    handleTime(data) {
      this.formInline.bootTime = dateFormat(data, "yyyy-MM-dd HH");
    },
    getBusinss() {
      let url = "/acb/2.0/manager/list";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.butreeData = [res.value];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          // this.parkType = data["parkType"];
          this.commercialStatus = data["commercialStatus"];
          this.parkEquipmentType = data["parkEquipmentType"];
          this.parkState = data["parkState"];
          this.payMode = data["payMode"];
          this.parkScope = data["parkScope"];
          this.cooperationModel = data["cooperationModel"];
          // this.slaveRelation = data["slaveRelation"];
          this.businessType = data["businessType"];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    createPark() {
      this.formInline.remoteControl = this.formInline.parkSystemType == 3 ? 1 : 0;
      if (this.date && this.date[0] && this.date[1]) {
        this.formInline.openStartTime = this.date[0];
        this.formInline.openStopTime = this.date[1];
      } else {
        this.$alert("请选择开放日期！");
        return;
      }
      if (this.formInline.slaveRelation == 1 && !this.formInline.firstLevelAmount) {
        this.$alert("一级车场泊位数为必填项");
        return;
      }
      // 临停+固定 <= 总泊位数
      const { parkAccessType, slaveRelation, fixedAmount, tempAmount, amount, firstLevelAmount } =
        this.formInline;
      if (
        parkAccessType === 3 &&
        slaveRelation == 1 &&
        Number(fixedAmount) + Number(tempAmount) > Number(amount)
      ) {
        this.$alert("子车场停泊位数应不大于总泊位数");
        return;
      }
      // 泊位数超过10位数会精度丢失
      if (String(amount)?.length > 10 || String(firstLevelAmount)?.length > 10) {
        this.$alert("泊位数不超过十位数");
        return;
      }
      // MB云盒模式-临停泊位赋值总泊位数
      if (this.formInline.parkAccessType === 1) {
        this.formInline.tempAmount = this.formInline.amount;
      }
      let url = this.isEdit ? "/acb/2.0/bacb/park/modify" : "/acb/2.0/bacb/park/add";
      let method = "post";
      if (this.isEdit) {
        this.formInline.parkId = this.$route.query.parkId;
      }
      let data = Object.assign({}, this.formInline);
      data.fixedBerthCarType = this.formInline.fixedBerthCarType.join();
      data.slaveRelation < 2 && delete data.parentId;
      delete data.date;
      this.$axios[method](url, {
        data: data,
      }).then((res) => {
        if (res.state == 0) {
          this.uploadData.parkId = this.isEdit ? this.$route.query.parkId : res.value;
          if (this.isEdit) {
            if (this.fileChang) {
              this.$refs.upload.submit();
            } else {
              this.$router.push("/parkManage");
            }
          } else {
            this.$refs.upload.submit();
          }
          this.$axios
            .post("acb/2.0/businessCenter/addPark", {
              data: {
                parkId: this.uploadData.parkId,
                businessCenterIds: this.formInline.hotValue.join(),
              },
            })
            .then((res) => {
              // console.log(res)
            });
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    addSub() {
      let nowTime = new Date();
      if (nowTime - this.timer > 1000) {
        this.timer = nowTime;
        this.submitData();
      } else {
        this.$alert("点击太快了，休息一下", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    submitData() {
      if (!this.imageUrl) {
        this.$alert("请选择图片", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.uploadImg) {
        const isJPG = this.uploadImg.raw.type === "image/jpeg";
        const isPNG = this.uploadImg.raw.type === "image/png";
        const isLt2M = this.uploadImg.raw.size / 1024 / 1024 < 2;
        if (!isJPG && !isPNG) {
          this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
          return;
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 2MB!");
          return;
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.createPark();
        } else {
          return false;
        }
      });
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/bacb/park/getParkVOById/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.formInline = res.value;
          // this.formInline.parkSystemType = 3
          if (this.formInline.parkSystemType != 0) {
            // this.$set(this.parkSystemType, 0, {
            //   desc: "AI值守",
            //   code: 0,
            //   disabled: true
            // })
            // console.log(this.parkSystemType)
          }
          this.date = [res.value.openStartTime, res.value.openStopTime];
          this.areaName = res.value.areaName;
          this.slaveRelationChange(res.value.slaveRelation);
          this.lnglat = res.value.longitude / 1000000 + "," + res.value.latitude / 1000000;
          this.formInline.fixedBerthCarType =
            res.value.fixedBerthCarType?.split(",").map((v) => Number(v)) || [];
          this.getParentPark();
          if (res.value.parentId != 0) {
            this.slaveRelationChange1(res.value.parentId);
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImg(parkId) {
      let url = "/acb/2.0/bacb/park/getImagebyId/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.imageUrl = res.value;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    filterMethod(query, item) {
      return item.businessCenterName.indexOf(query) > -1;
    },
    // 热门商圈
    getHotData() {
      let url = "acb/2.0/businessCenter/getAll";
      this.$axios.get(url).then((res) => {
        this.hotData = res.value;
      });
    },
    getHotAreaDetails(id) {
      let url = "acb/2.0/businessCenter/getByParkId";
      this.$axios
        .get(url, {
          data: {
            parkId: id,
          },
        })
        .then((res) => {
          this.$set(
            this.formInline,
            "hotValue",
            res.value.map((res) => {
              return res.businessCenterId;
            })
          );
          // this.formInline.hotValue = res.value.map(res => {
          //   return res.businessCenterId
          // })
          // console.log(this.formInline.hotValue)
        });
    },
    // 停车场支持选2张图片
    handleImgChange(file, fileList) {
      console.log(file, fileList, "change");
      this.fileChang = true;
      this.hideUpload = fileList.length >= this.limitCount;
      this.parkImgList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileChang = true;
      this.hideUpload = fileList.length >= this.limitCount;
      this.parkImgList = fileList;
    },
    beforeRemove(file, fileList) {
      if (file.parkId) {
        let url = "/acb/2.0/park/removeParkImage";
        this.$axios.get(url, { data: { id: file.parkId } }).then((res) => {
          if (res.state == 0) {
          } else {
          }
        });
      }
    },
    // 获取车辆组列表
    getList() {
      this.$axios
        .get("/acb/2.0/bacb/parkCarGroup/parkCarGroupType", {
          data: {},
        })
        .then((res) => {
          this.carGroup = res.value || [];
        });
    },
    onChangeCommercialStatus(val) {
      // if (this.formInline.commercialStatus)
      if (val == 1) {
        this.$confirm(
          "车场下线后，会将所有在场车辆按照僵尸车进行处理，成功下线后将不再接受该车场数据。"
        )
          .then((res) => {
            if (res === "confirm") {
              this.tempCommercialStatus = val;
            }
          })
          .catch(() => {
            this.formInline.commercialStatus = this.tempCommercialStatus;
          });
      } else {
        this.tempCommercialStatus = val;
      }
      // console.log(
      //   "mamz",
      //   "数据：" + this.formInline.commercialStatus,
      //   "临时：" + this.tempCommercialStatus
      // );
    },
  },
  components: {
    mapCon,
    treeCon,
  },
  created() {
    //  this.getArea();
    //  this.getBusinss();
    this.getList();
    this.getTenantList();
    this.queryTypeList();
    this.getHotData();
    // this.getParentPark();
    if (this.$route.query.parkId) {
      this.isEdit = true;
      this.getParkImg(this.$route.query.parkId);
      this.getParkDetail(this.$route.query.parkId);
      setTimeout(() => {
        this.getHotAreaDetails(this.$route.query.parkId);
      }, 150);
      this.effective = "2";
    }
  },
  mounted() {
    this.slaveRelation = [
      {
        code: 0,
        desc: "独立车场",
        disabled: false,
      },
      {
        code: 1,
        desc: "一级车场",
        disabled: false,
      },
      {
        code: 2,
        desc: "二级车场",
        disabled: false,
      },
      {
        code: 3,
        desc: "三级车场",
        disabled: false,
      },
      {
        code: 4,
        desc: "四级车场",
        disabled: false,
      },
    ];
    this.parkType = [
      {
        code: 1,
        desc: "地上",
      },
      {
        code: 2,
        desc: "地下",
      },
      {
        code: 3,
        desc: "地上和地下",
      },
    ];
  },
  computed: {
    computedAmount() {
      return 3;
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">

</style>
<style>

</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.form-footer {
  padding: 12px 40px;
  background: #fff;
  min-width: 1200px;
  box-sizing: border-box;
  position: sticky;
  bottom: -20px;
  z-index: 1;
  .el-button {
    padding: 11px 20px;
  }
}
.map1 {
  margin-left: 8px;
  padding: 11px 15px;
}
.gdbw-select >>> .el-tag__close {
  display: none;
}
/deep/.content {
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  &:not(&:first-child) {
    cursor: pointer;
  }
  .el-textarea__inner, .el-input__inner{
    width: 300px;
    height: 36px;
  }
  .el-input--small .el-input__inner {
    width: 149px;
    height: 28px;
    border-radius: 2px;
  }
  .custom-select {
    .el-input {
      width: 145px;
      .el-input__inner {
        width: 145px;
      }
    }
  }
}
.uploadPic {
  width: 100%;
  margin-left: 58px;
  display: flex;
  .avatar-uploader-icon2, .avatar2 {
    width: 368px;
    height: 225px;
    line-height: 225px;
    font-size: 28px;
  }
  /deep/.avatar-uploader2 .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 10px;
  }

  .avatar-uploader2 .el-upload:hover {
    border-color: #0f6eff;
  }
}
/deep/.lng-box {
  position: relative;
  .el-input, .el-input__inner {
    width: 206px;
  }
}
.mt20 {
  margin-top: 20px;
}

.title-box {
  width: 100%;
  display: flex;
  i {
    margin-right: 6px;
  }
  span {
    font-size: 15px;
    color: #2F2F51;
  }
}
.park-title {
  font-size: 14px;
  color: #333333;
  margin-bottom: 16px;

  &:before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
  }
}
.flex-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.flex {
  display: flex;

  .duty-mode {
    ::v-deep .el-form-item__content {
      margin-left: 10px !important;
    }
  }
}

.el-select {
  width: 100%;
}

.lng-box {
  position: relative;
}

.ing-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-41%);
  width: 19px;
  cursor: pointer;

  img {
    width: 100%;
  }
}
.park-icon-img {
  position: relative;
  display: block;
  font-style: normal;

  .park-icon-box, .park-icon-upload {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .park-icon-upload {
    width: 43px;
    height: 43px;
    top: 41%;
  }

  .park-icon-box {
    width: 74%;
    text-align: left;
    top: 72%;
    line-height: 20px;
  }
}

.third-box {
  margin-bottom: 0;
  width: 32%;
}

.length-box .el-input {
  width: 95%;
  margin-right: 2%;
}

.tips-box {
  position: absolute;
  bottom: -33px;
  color: #AAAAAA;
  font-size: 13px;
  width: 180%;
}

/deep/ .textarea-input {
  .el-textarea__inner {
    width: 224px;
  }
  textarea::-webkit-input-placeholder {
    font-family: 'PingFang';
  }
}
.dialog_button {
  width: 300px;
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #f2f3f5;
  &.el-button {
    padding: 11px 20px;
  }
}
.isMark {
  width: 302%;
  color: red;
  margin-bottom: -25px;
  margin-left: -180px;
  z-index: 99;
}
</style>
